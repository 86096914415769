import {
  computed,
  makeObservable,
  observable,
  values,
  action,
  toJS,
} from 'mobx';
import React from 'react';
import { v4 as uuid } from 'uuid';
import {
  CHAT_EVENT_TYPE,
  CONTENT_TYPE,
  MESSAGE_TYPE,
  TICKET_STATUS,
} from '../const/ChatConst.js';
import CommonHelper from '../helper/CommonHelper.js';
import moment from 'moment';
import { debounce, sortedIndex } from 'lodash';
import axios from 'axios';

class ChatStore {
  constructor(rsocketStore) {
    this.rsocketStore = rsocketStore;
    makeObservable(this);
  }

  setRSocketStore(rsocketStore) {
    this.rsocketStore = rsocketStore;
  }

  rsocketStore;

  @computed
  get ticketStore() {
    return this.rsocketStore.ticketStore || undefined;
  }

  @computed
  get channelStore() {
    return this.rsocketStore.channelStore || undefined;
  }

  setQuickMsgStore(quickMsgStore) {
    this.quickMsgStore = quickMsgStore;
  }

  @observable
  quickMsgStore;

  stream;

  @computed
  get rsocket() {
    return this.rsocketStore.rsocket;
  }

  connectChannel = () => {
    let store = this;
    const r = store.requestStream();
    store.stream = r;
  };

  requestStream = () => {
    return this.rsocketStore.requestStream((payload, isComplete) => {
      let isBottom = this.isBottom;
      let message = CommonHelper.deserialize(payload.data);
      this.onReceivedMessage(message).then((r) => {
        if (isBottom) {
          this.debounceScrollToBottom();
        } else {
          this.isNewMessage = true;
        }
      });
    });
  };

  resetChannel = async (channel) => {
    let store = this;
    store.isOpenResendDialog = false;
    store.isAssigned = false;
    store.isOpenClose = false;
    store.messageMap.clear();
    store.recommendMap.clear();
    store.messageCount = 0;
    store.isInitialLoad = true;
    store.keyword = '';
    store.isSearchMode = false;
    store.channel = channel;
    store.channelId = channel?.id;
  };

  setChannel = async (channel) => {
    let store = this;
    store.isReset = true;
    let response;
    try {
      response = await store.rsocketStore.requestChatApi(
        `/ticket/detail/${channel.id}`,
        {},
      );
      store.resetChannel(response.data).then((r) => {
        store.isReset = false;
        store.hasMore = true;
      });
    } catch (e) {
      store.resetChannel(undefined).then((r) => {
        store.isReset = false;
        store.hasMore = true;
      });
      // throw 'Ticket not found';
    }
  };

  toggleIsSearchMode = () => {
    let store = this;
    store.keyword = '';
    store.isSearchMode = !store.isSearchMode;
  };

  @observable
  isSearchMode = false;

  @observable
  isFetching = false;

  @observable
  isReset = false;

  @computed
  get sender() {
    return this.rsocketStore?.sender;
  }

  @observable
  messageCount = 0;

  @observable
  messageMap = new Map();

  @observable
  recommendMap = new Map();

  @observable
  typingUser = new Map();

  @computed
  get typingUsers() {
    return values(this.typingUser);
  }

  @observable
  typingSetTimeout = new Map();

  @observable
  searchIndex = 0;

  @observable
  keyword = '';

  @computed
  get messages() {
    let store = this;
    if (store.channelId) {
      return toJS(values(this.messageMap))
        .filter((message) => message.channelId == store.channelId)
        .sort((a, b) => moment(a.createdAt).isAfter(b.createdAt));
    } else return [];
  }

  @computed
  get lastUserMessage() {
    let userMessages = this.messages.filter(
      (message) => message?.type != 'ADMIN',
    );
    return userMessages.length > 0
      ? userMessages[userMessages.length - 1]
      : undefined;
  }

  @computed
  get lastMessage() {
    return this.messages.length > 0
      ? this.messages[this.messages.length - 1]
      : undefined;
  }

  @computed
  get firstMessage() {
    return this.messages.length > 0 ? this.messages[0] : undefined;
  }

  @computed
  get searchedMessages() {
    if (!this.keyword) return [];
    return (
      this.messages
        .filter((message) => message?.jsonContent?.includes(this.keyword))
        ?.reverse() || []
    );
  }

  onImageLoadHandler = (isBottom) => {
    let store = this;
    if (isBottom) store.debounceScrollToBottom();
  };

  @observable
  isOptionOpen = false;

  @observable
  isSettingOpen = false;

  scrollRef = React.createRef();

  messageEndRef = React.createRef();

  @observable
  isInitialLoad = false;

  @observable
  hasMore = false;

  @observable
  isBottom = true;

  @observable
  isNewMessage = false;

  @observable
  channel = undefined;

  @observable
  isAssigned = false;

  updateAssigned() {
    this.isAssigned = true;
  }

  @computed
  get isRespond() {
    return !!this.channel?.respondAt || this.isAssigned;
  }

  @computed
  get status() {
    return this.channel?.status || TICKET_STATUS.CLOSED;
  }

  @observable
  channelId = '';

  @observable
  isNotFoundMessage = false;

  @observable
  replyMessage = undefined;

  @observable
  uploadedFile = undefined;

  @observable
  uploadedType = CONTENT_TYPE.IMAGE;

  @observable
  scenarioNode = undefined;

  @observable
  isFailureCount = undefined;

  @observable
  recommendMessage = undefined;

  @computed
  get isPrevSearchable() {
    let store = this;
    return (
      store.keyword &&
      (store.hasMore ||
        store.searchIndex < store.searchedMessages.length - 1 ||
        !store.isNotFoundMessage)
    );
  }

  @computed
  get isNextSearchable() {
    let store = this;
    return store.keyword && store.searchIndex > 0;
  }

  onScrollHandler = (e) => {
    let store = this;
    let isBottom =
      store?.scrollRef?.current?.scrollHeight -
        store?.scrollRef?.current?.clientHeight <=
      store?.scrollRef?.current?.scrollTop + 60;
    if (isBottom) {
      store.isNewMessage = false;
      if (store.channel) {
        store.debounceConfirmMessage();
      }
    }
    store.isBottom = isBottom;
  };

  debounceScrollToBottom = debounce(() => this.scrollToBottom(), 100);

  scrollToBottom = () => {
    let store = this;
    store.messageEndRef?.current?.scrollIntoView?.({
      block: 'end',
      inline: 'nearest',
      behavior: 'auto',
    });
    if (store.channel) {
      if (
        store?.scrollRef?.current?.scrollHeight ==
        store?.scrollRef?.current?.clientHeight
      ) {
        store.debounceConfirmMessage();
      }
    }
  };

  clearReply = () => {
    let store = this;
    store.replyMessage = undefined;
  };

  clearUploadFile = () => {
    let store = this;
    store.uploadedFile = undefined;
    store.uploadedType = CONTENT_TYPE.IMAGE;
  };

  @action.bound
  onSendMessage = async (message, file) => {
    let store = this;
    let id = uuid();
    let textContent = message.content;
    let content;
    let sendMessage = {
      ...message,
      id,
      channelId: store.channelId,
      type: MESSAGE_TYPE.NORMAL,
      isSending: true,
      isSent: false,
      sender: store.sender,
      replyMessage: store.replyMessage,
      isConfirm: false,
      scenarioNode: store.scenarioNode,
      isFailureCount: store.isFailureCount,
      recommendMessage: store.recommendMessage,
    };
    if (
      !store.uploadedFile &&
      message.contentType === CONTENT_TYPE.TEXT &&
      !textContent.value?.trim()
    )
      return;

    store.messageMap.set(id, sendMessage);

    if (textContent?.value) {
      content = textContent;
    }
    if (store.uploadedFile) {
      let { data: resultContent } = await store.rsocketStore.requestChatApi(
        `/channel/file/upload`,
        {
          channel: store.channel,
          file: store.uploadedFile,
        },
      );
      let fileContent = { ...resultContent, type: store.uploadedType };
      if (textContent?.value) {
        content = {
          type: CONTENT_TYPE.CUSTOM,
          value: [fileContent, textContent],
        };
      } else {
        content = fileContent;
      }
    }
    store.send(store.channelId, { ...sendMessage, content });
  };

  @action.bound
  loadMore = async () => {
    let store = this;
    if (store.isFetching) return;
    store.isFetching = true;
    let messages = await store.loadPreviousMessage();
    store.isFetching = false;
    return messages;
  };

  @action.bound
  loadPreviousMessage = async () => {
    let store = this;
    if (!store.channelId) return;
    let {
      data: { items, rowsCount, hasMore = false },
    } = await store.rsocketStore.requestChatApi(`/channel/messages`, {
      channelId: store.channelId,
      count: 50,
      createdAt: store.messages[0]?.createdAt || null,
      prevId: store.messages[0]?.id || null,
    });

    let recommendData = items.filter(
      (item) => item.type == MESSAGE_TYPE.RECOMMEND,
    );
    let recommendMessages = recommendData.reverse();

    items = items.filter((item) => item.type != MESSAGE_TYPE.RECOMMEND);

    store.messageCount = rowsCount - recommendMessages.length;
    store.hasMore = hasMore;
    let messages = items.reverse();

    store.messageMap.replace(
      messages.concat(store.messages).map((message) => [message.id, message]),
    );

    if (recommendMessages.length > 0) {
      store.recommendMap.set(store.channelId, recommendMessages);
    }

    messages.map((item) => {
      if (item.scenarioNode != undefined) {
        this.scenarioNode = item.scenarioNode;
      }
    });

    return messages;
  };

  @action.bound
  onReceivedMessage = async (message) => {
    let store = this;
    let origin = store.messageMap.get(message.id);
    let sender = message.sender;
    let ticketStore = store.ticketStore;

    store.scenarioNode = message.scenarioNode;
    store.isFailureCount = message.isFailureCount;
    if (ticketStore) {
      let channelId = message.channelId;
      let ticketMap = ticketStore.ticketMap;
      let recommendMap = ticketStore.recommendMap;
      let originTicket = ticketMap.get(message.channelId);
      if (ticketMap.has(channelId)) {
        let notConfirmCount = originTicket.notConfirmCount || 0;
        if (store.channelId != message.channelId) {
          if (
            message.type != MESSAGE_TYPE.ADMIN &&
            !store.messageMap.has(message.id) &&
            sender?.id != store?.sender?.id
          )
            notConfirmCount += 1;
        }
        if (message?.type == MESSAGE_TYPE.RECOMMEND) {
          recommendMap.set(message.channelId, {
            ...originTicket,
            lastMessage: message,
            notConfirmCount,
          });
        } else {
          ticketMap.set(message.channelId, {
            ...originTicket,
            lastMessage: message,
            notConfirmCount,
          });
        }
      }
    }

    // recommand 따로 저장
    if (message?.type == MESSAGE_TYPE.RECOMMEND) {
      const originRecommends = store.recommendMap.get(message.channelId) || [];
      return store.recommendMap.set(message.channelId, [
        ...originRecommends,
        { ...origin, ...message },
      ]);
    } else {
      return store.messageMap.set(message.id, { ...origin, ...message });
    }
  };

  send = async (channel, message) => {
    let store = this;
    if (store.rsocketStore.rsocket.connection._done) {
      let origin = store.messageMap.get(message.id);
      store.messageMap.set(message.id, {
        ...origin,
        isSending: false,
        isSent: false,
      });
    } else {
      return store.rsocket.requestResponse(
        {
          data: CommonHelper.serialize(message),
          metadata: CommonHelper.generateMetadata(`channel/${channel}/send`),
        },
        {
          onNext: (payload, isComplete) => {
            // debugger;
            let returnMessage = CommonHelper.deserialize(payload.data);
            if (store.messageMap.has(message.id)) {
              let origin = store.messageMap.get(message.id);
              store.messageMap.set(message.id, {
                ...origin,
                ...returnMessage,
                isSending: false,
                isSent: true,
              });
            } else {
              store.messageMap.set(returnMessage?.id, returnMessage);
            }
          },
          onComplete: (a) => {},
          onError: (error) => {
            let origin = store.messageMap.get(message.id);
            store.messageMap.set(message.id, {
              ...origin,
              isSending: false,
              isSent: false,
            });
          },
        },
      );
    }
  };

  setSender(sender) {
    this.channelId = undefined;
    this.sender = sender;
  }

  async sendChatEvent(chatEventType) {
    if (!chatEventType) return;
    let store = this;
    let chatEvent = {
      type: chatEventType,
      channelId: store.channelId,
      sender: store.sender,
    };
    new Promise((resolve, reject) => {
      store.rsocket.fireAndForget(
        {
          data: CommonHelper.serialize(chatEvent),
          metadata: CommonHelper.generateMetadata(`event/send`),
        },
        {
          onComplete: (a) => {
            resolve();
          },
          onError: (error) => {
            reject();
          },
        },
      );
    });
  }

  scrollToMessage = async (message) => {
    let store = this;
    let messageDiv = document.getElementById(`${message?.id}_message`);
    store.messageMap.set(message?.id, {
      ...message,
      focus: true,
    });
    store.scrollRef.current.scrollTo(0, messageDiv?.offsetTop);
  };

  scrollToReplyMessage = async (message) => {
    let store = this;
    let replyMessage = store.messageMap.get(message.id);
    if (replyMessage) {
      await store.scrollToMessage(replyMessage);
    } else {
      await store.searchDatabaseMessage({ message });
      let searchMessage = store.messageMap.get(message.id);
      if (searchMessage) {
        await store.scrollToMessage(searchMessage);
      }
    }
  };

  searchMessage = async () => {
    let store = this;
    let searchMessage = store.searchedMessages[store.searchIndex];
    if (searchMessage) {
      await store.scrollToMessage(searchMessage);
    } else {
      searchMessage = await store.searchDatabaseMessage({
        keyword: store.keyword,
      });
      if (searchMessage) {
        await store.scrollToMessage(searchMessage);
      }
    }
  };

  searchDatabaseMessage = async ({ message, keyword }) => {
    let store = this;
    store.isFetching = true;
    let result = await store.rsocketStore
      .requestChatApi(`/channel/search/messages`, {
        channelId: store.channelId,
        keyword,
        message,
        createdAt: store.firstMessage?.createdAt || null,
        prevId: store.firstMessage?.id || null,
      })
      .catch(
        ({
          response: {
            data: { message },
          },
        }) => {
          store.isNotFoundMessage = true;
          alert(message);
        },
      );
    store.isFetching = false;
    if (result) {
      let messages = result.data.map((message) => message).reverse();
      store.messageMap.replace(
        messages.concat(store.messages).map((message) => [message.id, message]),
      );
      return messages[0];
    }
    return undefined;
  };

  ticketRating = async (rating, comment) => {
    let store = this;
    return await store.rsocketStore.requestChatApi('/ticket/rating', {
      id: store.channelId,
      rating,
      comment,
    });
  };

  initSearchMessage = async (value) => {
    let store = this;
    store.isNotFoundMessage = false;
    store.searchIndex = 0;
    store.keyword = value;
    store.searchMessage();
  };

  prevSearchMessage = async () => {
    let store = this;
    store.searchIndex += 1;
    store.searchMessage();
  };

  nextSearchMessage = async () => {
    let store = this;
    store.searchIndex -= 1;
    let searchMessage = store.searchedMessages[store.searchIndex];
    if (searchMessage) {
      await store.scrollToMessage(searchMessage);
    }
  };

  debounceConfirmMessage = debounce(
    () => {
      let store = this;
      let confirmMessage = store.messages.filter(
        (message) =>
          message.isConfirm === false &&
          message?.sender?.id !== store?.sender?.id &&
          message.type != MESSAGE_TYPE.ADMIN,
      );
      if (confirmMessage.length > 0)
        store.allConfirmMessage(confirmMessage).then((r) => {});
    },
    500,
    { leading: true },
  );

  allConfirmMessage = async (messages) => {
    let store = this;
    messages
      .filter(
        (message) =>
          !message.isConfirm && message?.sender?.id != store?.sender?.id,
      )
      .map((message) => {
        let origin = store.messageMap.get(message.id);
        let count = origin?.notConfirmCount;
        store.messageMap.set(message.id, {
          ...origin,
          notConfirmCount: count - 1,
          isConfirm: true,
        });
      });
    await store.rsocketStore.requestChatApi(
      '/channel/message/confirm',
      messages,
    );
    if (store.ticketStore) {
      let ticketMap = store.ticketStore.ticketMap;
      if (ticketMap.has(store.channelId)) {
        let origin = ticketMap.get(store.channelId);
        ticketMap.set(store.channelId, { ...origin, notConfirmCount: 0 });
      }
    }
  };

  close = async () => {
    let store = this;
    let ticketStore = store.ticketStore;
    if (ticketStore) {
      ticketStore.close(store.channelId).then((r) => {
        store.channelId = undefined;
        store.channel = undefined;
      });
    }
  };

  innerLinkFn;

  setInnerLinkFn(innerLinkFn) {
    this.innerLinkFn = innerLinkFn;
  }

  quickMsgFn;

  setQuickMsgFn(quickMsgFn) {
    this.quickMsgFn = quickMsgFn;
  }

  get isSendAble() {
    let store = this;
    if (store.quickMsgStore) {
      return !store.quickMsgStore.isOpen;
    } else {
      return true;
    }
  }

  async transfer(assignment) {
    let store = this;
    await store.rsocketStore.requestChatApi('/ticket/transfer', {
      ticket: store.channel,
      ...assignment,
    });
    store.transferAfterTodo && store.transferAfterTodo();
    return true;
  }

  closeAllModal(ticket) {
    let store = this;
    store.isOpenAgentTransfer = false;
    store.isOpenTeamTransfer = false;
    if (store.quickMsgStore) {
      store.quickMsgStore.setIsOpen(false);
    }
  }

  @observable
  isOpenAgentTransfer = false;

  @observable
  isOpenTeamTransfer = false;

  @observable
  transferAfterTodo;

  openAgentTransfer = (todo) => {
    let store = this;
    store.isOpenAgentTransfer = true;
    store.transferAfterTodo = todo;
  };
  closeAgentTransfer = () => {
    let store = this;
    store.isOpenAgentTransfer = false;
  };

  openTeamTransfer = (todo) => {
    let store = this;
    store.isOpenTeamTransfer = true;
    store.transferAfterTodo = todo;
  };
  closeTeamTransfer = () => {
    let store = this;
    store.isOpenTeamTransfer = false;
  };

  unAssigned = (ticket) => {
    let store = this;
    if (store.channelId == ticket.id) {
      store.openCloseDialog(CHAT_EVENT_TYPE.UNASSIGNED);
    }
  };

  closed = (ticket) => {
    let store = this;
    if (ticket.id == store.channelId) {
      store.openCloseDialog(CHAT_EVENT_TYPE.CLOSE);
    }
    store.closeAllModal(ticket);
  };

  @observable
  isOpenClose = false;

  @observable
  finishedType = CHAT_EVENT_TYPE.CLOSE;

  openCloseDialog(type) {
    let store = this;
    store.isOpenClose = true;
    store.finishedType = type;
  }

  onNotificationHandler(type, message) {
    return this.rsocketStore.onNotificationHandler(type, message);
  }

  onUserTypingHandler(type, chatEvent) {
    let { sender, channelId } = chatEvent;
    let store = this;
    if (type == CHAT_EVENT_TYPE.TYPING_START) {
      let key = `${channelId}_${sender.id}`;
      let isBottom = store.isBottom;
      store.typingUser.set(key, { ...sender, channelId });
      if (isBottom) {
        store.debounceScrollToBottom();
      }
      if (store.typingSetTimeout.has(key)) {
        clearTimeout(store.typingSetTimeout.get(key));
      }
      if (sender?.type == 'AGENT_BOT') return;
      let timeout = setTimeout(() => {
        store.typingUser.delete(key);
      }, 2000);
      store.typingSetTimeout.set(key, timeout);
    } else if (type == CHAT_EVENT_TYPE.TYPING_END) {
      let key = `${channelId}_${sender.id}`;
      store.typingUser.delete(key);
    }
  }

  onMessageConfirmHandler(type, chatEvent) {
    let store = this;
    let { message } = chatEvent;
    if (type == CHAT_EVENT_TYPE.NOT_CONFIRM_COUNT) {
      let { sender } = message;
      let origin = store.messageMap.get(message.id);
      store.messageMap.set(message.id, {
        ...origin,
        notConfirmCount: message.notConfirmCount,
        isConfirm: sender.id != store.sender.id ? false : true,
      });
    } else if (type == CHAT_EVENT_TYPE.CONFIRM_MESSAGE) {
      let origin = store.messageMap.get(message.id);
      let count = origin?.notConfirmCount;
      store.messageMap.set(message.id, {
        ...origin,
        notConfirmCount: count - 1,
      });
    }
  }

  @observable
  isFileUploading = false;

  @observable
  progress = 0;

  source;

  uploadFile = async (formData) => {
    let store = this;
    if (store.source) {
      store.source.cancel();
      store.progress = 0;
    }
    store.isOptionOpen = false;
    store.isFileUploading = true;
    store.source = axios.CancelToken.source();
    let { data: result } = await store.rsocketStore
      .requestChatMultipartApi(`/app/upload/draft`, formData, {
        onUploadProgress: (event) =>
          (store.progress = (event.loaded / event.total) * 100),
        cancelToken: store.source.token,
      })
      .catch((e) => {
        if (e.message !== 'canceled') {
          store.isFileUploading = false;
        }
      });
    store.isFileUploading = false;
    store.progress = 0;
    store.beforeSource = undefined;
    store.source = undefined;

    return result;
  };

  @observable
  isOpenResendDialog = false;

  @observable
  targetMessage;

  openResendDialog(message) {
    let store = this;
    store.targetMessage = message;
    store.isOpenResendDialog = true;
  }

  closeSendDialog() {
    let store = this;
    store.targetMessage = undefined;
    store.isOpenResendDialog = false;
  }

  resendMessage(message) {
    let store = this;
    store.messageMap.delete(message?.id);
    store.send(store.channelId, message).then((r) => {
      store.scrollToBottom();
    });
    store.closeSendDialog();
  }

  deleteMessage(message) {
    let store = this;
    store.messageMap.delete(message?.id);
    store.closeSendDialog();
  }

  clear() {
    let store = this;
    store.setChannel(undefined);
  }
}

export { ChatStore };
