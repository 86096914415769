import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import Row from './Row.js';
import Pagination from './Pagination.js';
import { Form, Table, Spinner } from 'react-bootstrap';
import Styles from './DataGrid.module.scss';
import { CSSTransition } from 'react-transition-group';
import Select from 'react-select';
import { FaFileExcel } from 'react-icons/fa';
import {
  ColumnPanelCheckBoxGroup,
  ColumnPanelStore,
} from './ColumnPanelCheckBox.js';
import { CSVLink } from 'react-csv';
import DataGridStore from '../../store/DataGridStore.js';

const styles = {
  menu: (base) => ({
    ...base,
    zIndex: 9,
    color: '#6C757D',
  }),
  container: (base) => ({
    ...base,
  }),
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(254 147 21 / 25%)' : 0,
    borderColor: state.isFocused ? '#ffc98a' : '#ced4da',
    minHeight: '0.75rem',
    '&:hover': {
      boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(254 147 21 / 25%)' : 0,
      borderColor: state.isFocused ? '#ffc98a' : '#ced4da',
    },
    zIndex: 1,
    borderRadius: '4px',
  }),

  singleValue: (base) => ({
    ...base,
    color: '#6C757D',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0.1625rem 1rem',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: '0 .25rem',
    transform: `rotate( 180deg )`,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0 .25rem',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: '0 .25rem',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    width: 0,
    margin: '.25rem 0',
  }),
};

/**
 * 데이터그리드 컴포넌트
 */
@observer
class DataGrid extends React.Component {
  headerEl = createRef();
  contentEl = createRef();

  /**
   * 컬럼 페널 스토어
   * @type {ColumnPanelStore}
   */
  @observable
  columnPanelStore;

  constructor(props, context) {
    super(props, context);
    const { columns, localStorageKey } = this.props;
    this.state = {
      excelData: null,
      excelLoading: false,
    };
    this.excelLinkRef = React.createRef();
    this.columnPanelStore = new ColumnPanelStore(
      columns || [],
      localStorageKey,
    );
    this.store.colMap.replace(
      columns.map((column) => [
        column.props.id,
        column.props.width || this.store.defaultWidth,
      ]),
    );
  }

  handleExcelClick = async () => {
    this.setState({ excelLoading: true });
    const columns = this.columnPanelStore.visibleColumns.map((v) => v.props);

    try {
      const fetchedData = await this.store.allFetch(columns);
      this.setState({ excelData: fetchedData });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ excelLoading: false });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const prevColumns = prevProps.columns;
    const { columns, localStorageKey } = this.props;
    if (prevColumns != columns) {
      this.columnPanelStore = new ColumnPanelStore(
        columns || [],
        localStorageKey,
      );
    }

    if (prevState.excelData !== this.state.excelData && this.state.excelData) {
      this.excelLinkRef.current.link.click();
    }
  }

  /**
   * 저장소
   * @return {DataGridStore}
   */
  get store() {
    return this.props.store;
  }

  csvDownload() {}

  @observable
  columnPanelEl = false;

  handleColumnPanelToggle = () => {
    this.isColumnPanelOpen = !this.isColumnPanelOpen;
  };

  render() {
    let {
      keyColumn,
      isHighlightFn,
      highlightClass,
      isCheckable = false,
      downloadFilename = 'DOWNLOAD.csv',
      filter,
      customButton,
    } = this.props;
    let items = this.store.noPaging
      ? this.store.internalSortedItems
      : this.store.dataSet.items;
    items = items.filter((product) => (filter ? filter(product) : true));
    let store = this.store;
    return (
      <div
        className={`${
          this.props.className || ''
        } d-flex flex-column w-100 h-100`}
      >
        {!this.store.noHeader && (
          <div className="d-flex justify-content-between py-1">
            <div
              className="d-flex align-items-center gap-1"
              style={{ fontSize: 12 }}
            >
              <ColumnPanelCheckBoxGroup
                columnPanelStore={this.columnPanelStore}
              />
              {this.store.dataSet.itemsCount > 0 && (
                <button
                  onClick={this.handleExcelClick}
                  className={`${Styles.ExcelIcon} ${Styles.HeaderButton} btn btn-outline-secondary btn-sm`}
                  disabled={
                    this.state.excelLoading ||
                    !this.columnPanelStore ||
                    !this.store
                  }
                >
                  {this.state.excelLoading ? (
                    '로딩 중...'
                  ) : (
                    <>
                      <FaFileExcel />
                      {`엑셀다운로드`}
                    </>
                  )}
                </button>
              )}
              <CSVLink
                headers={this.columnPanelStore.visibleColumns.map((c) => ({
                  label: c.props.name,
                  key: c.props.id,
                }))}
                data={this.state.excelData || []}
                filename={downloadFilename}
                className="d-none"
                ref={this.excelLinkRef}
                target="_blank"
              />
              {customButton}
              <span className="ms-1">
                조회건수 : {this.store.dataSet.itemsCount}
              </span>
            </div>
          </div>
        )}
        <div
          className={`d-flex flex-column overflow-hidden`}
          style={{ flex: 1 }}
        >
          <div
            className={`${Styles.TableHeader}`}
            ref={this.headerEl}
            onScroll={(el) => {
              if (this.contentEl.current)
                this.contentEl.current.scrollLeft = el.target.scrollLeft;
            }}
          >
            <Table className="table-hover m-0 w-100">
              <thead>
                <tr className={Styles.TableTr}>
                  {
                    // 전체 선택 버튼
                    isCheckable && (
                      <th style={{ width: 16 }}>
                        <div className={Styles.CheckBox}>
                          <Form.Check
                            onChange={(e) =>
                              this.store.toggleAllRows(e.target.checked)
                            }
                            checked={this.store.isAllChecked}
                          />
                        </div>
                      </th>
                    )
                  }
                  {this.columnPanelStore.visibleColumns.map((column) => (
                    <column.type
                      key={column.props.id}
                      store={this.store}
                      scrollRef={this.scrollRef}
                      headerRef={this.headerEl.current}
                      {...column.props}
                    />
                  ))}
                </tr>
              </thead>
            </Table>
          </div>
          <div
            className={`d-flex flex-column flex-default position-relative`}
            style={{ flex: 1 }}
          >
            <div
              className={`overflow-auto`}
              style={{ flex: '1 1 0' }}
              ref={this.contentEl}
              onScroll={(el) => {
                if (this.headerEl.current)
                  this.headerEl.current.scrollLeft = el.target.scrollLeft;
              }}
            >
              <Table className="table-hover">
                <tbody className={`d-block`}>
                  {items.length === 0 ? (
                    <tr
                      className={Styles.TableTr}
                      style={{ borderBottom: '1px solid #CED4DA' }}
                    >
                      <td>
                        <div
                          style={{
                            width: store.getNoDataWidth({
                              isCheckable,
                              visibleCol: this.columnPanelStore.visibleColumns,
                            }),
                          }}
                        >
                          조회된 데이터가 없습니다.
                        </div>
                      </td>
                    </tr>
                  ) : (
                    items.map((rowData, idx) => {
                      return (
                        <Row
                          key={`${rowData[keyColumn]}_${idx}`}
                          columns={this.columnPanelStore.visibleColumns.map(
                            (c) => c.props,
                          )}
                          onClick={() => {
                            if (this.props.onRowClick)
                              this.props.onRowClick(rowData);
                          }}
                          store={this.store}
                          keyColumn={keyColumn}
                          rowData={rowData}
                          highlightClass={highlightClass}
                          isHighlightFn={isHighlightFn}
                          isCheckable={isCheckable}
                        />
                      );
                    })
                  )}
                </tbody>
              </Table>
              <CSSTransition
                in={this.store.isFetching}
                timeout={200}
                classNames="fade"
                unmountOnExit
              >
                <div className={Styles.BackDrop}>
                  <Spinner animation="border" size="sm" variant="success" />
                </div>
              </CSSTransition>
            </div>
          </div>
        </div>
        <div className={'d-flex justify-content-between mt-2'}>
          <div>
            총{' '}
            <span style={{ fontWeight: 400, color: '#007DFC' }}>
              {this.store.dataSet.itemsCount}
            </span>
            건
          </div>
          {!this.store.noPaging && (
            <div className={'d-flex justify-content-center'}>
              <Pagination store={this.store} />
            </div>
          )}
          {!this.store.noPaging && this.store.isChangeRowsPerPage && (
            <div className="d-flex">
              <Select
                className="me-2"
                menuPlacement={'top'}
                styles={styles}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                })}
                options={[
                  { label: `10 개씩 보기`, value: 10 },
                  { label: `20 개씩 보기`, value: 20 },
                  { label: `50 개씩 보기`, value: 50 },
                ]}
                onChange={({ value }) => {
                  this.store.changeRowsPerPage(value);
                }}
                value={{
                  label: this.store.rowsPerPage + ' 개씩 보기',
                  value: this.store.rowsPerPage,
                }}
              ></Select>
              {/*<div className="d-flex align-items-center">*/}
              {/*    개씩 보기*/}
              {/*</div>*/}
            </div>
          )}
        </div>
      </div>
    );
  }
}

DataGrid.propTypes = {
  /**
   * 데이터 스토어
   */
  store: PropTypes.instanceOf(DataGridStore).isRequired,

  /**
   * 컬럼배열
   */
  columns: PropTypes.arrayOf(PropTypes.node).isRequired,

  /**
   * 식별자 컬럼
   */
  keyColumn: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,

  /**
   * 행 클릭시 함수
   */
  onRowClick: PropTypes.func,
  /**
   * 페이징 영역
   */
  noPagination: PropTypes.bool,
  isHighlightFn: PropTypes.func,
  highlightClass: PropTypes.string,
  isCheckable: PropTypes.bool,
  downloadFilename: PropTypes.string,
  /**
   * 컬럼 저장용 로컬 스토리지 키(메뉴ID).
   */
  localStorageKey: PropTypes.string,
  customButton: PropTypes.node,
};

export default DataGrid;
